import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import { Box, IconButton, InputBase, PaletteMode, SxProps } from '@mui/material';
import debounce from 'lodash/debounce';
import useTranslation from 'next-translate/useTranslation';
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchBox, UseSearchBoxProps } from 'react-instantsearch';
import useRecentSearchMethods, { RecentSearch } from '../RecentSearch/hooks/useRecentSearchMethods';
import InfoIconWithHints from './InfoIconWithHints';
import UnderbarCard from './UnderbarCard';
import { MAX_SEARCHES } from '@utils/consts/search';
import useAmplitude from '@utils/hooks/useAmplitude';
import { TrackingEventName } from '@utils/types/amplitude/events';

const containerSx: SxProps = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
};

const searchQueryContainerSx: SxProps = {
    alignContent: 'center',
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    pl: 2,
    width: '100%',
};

const searchQueryWithIconSx: SxProps = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
};

const infoIconSx: SxProps = {
    alignItems: 'center',
    display: 'flex',
    pt: '2px',
};

export const searchButtonSx: SxProps = {
    '&:hover': {
        bgcolor: 'primary.dark',
    },
    bgcolor: 'primary.main',
    borderRadius: 0.5,
    boxShadow: 0,
    color: 'grey.800',
};

export const searchInputBaseSx: (colorVersion: string) => SxProps = (colorVersion) => ({
    '& input': {
        color: colorVersion === 'dark' ? 'common.white' : 'grey.100',
        fontSize: 14,
        fontWeight: 600,
        padding: 0,
        width: '100%',
    },
    '& input::placeholder': {
        color: 'grey.400',
        fontWeight: 400,
    },
    border: 'none',
    padding: 0,
    width: '100%',
});

const shortcutHelperIconSx: SxProps = {
    alignItems: 'center',
    bgcolor: 'grey.300',
    border: 1,
    borderColor: 'grey.400',
    borderRadius: 0.5,
    color: 'common.white',
    display: 'flex',
    float: 'right',
    height: '26px',
    justifyContent: 'center',
    ml: 'auto',
    mr: 3,
    pt: '2px',
    width: '34px',
};

interface SearchBoxProps extends UseSearchBoxProps {
    autoFocus?: boolean;
    colorVersion?: PaletteMode;
    hints?: string[];
    onChange?: (searchValue: string) => void;
    onSearchClick?: (searchValue: string) => void;
    placeholder?: string;
    searchAsYouType?: boolean;
    withHints?: boolean;
    withShortcutHelper?: boolean;
    withSubmitButton?: boolean;
    withUnderbarCard?: boolean;
}

const AlgoliaSearchBox: FC<SearchBoxProps> = ({
    autoFocus = false,
    colorVersion = 'light',
    hints,
    onChange,
    onSearchClick: onSearch,
    placeholder,
    searchAsYouType = true,
    withHints,
    withShortcutHelper,
    withSubmitButton,
    withUnderbarCard,
    ...searchBoxProps
}) => {
    const { t } = useTranslation('common');
    const { getItems, setItem } = useRecentSearchMethods();
    const { query, refine } = useSearchBox(searchBoxProps);
    const [searchValue, setSearchValue] = useState(query);
    const [showUnderbarCard, setShowUnderbarCard] = useState(false);
    const [showHints, setShowHints] = useState(false);
    const { track } = useAmplitude();

    const previousSearches: RecentSearch[] = getItems();
    const hintsDisplayed = hints || ['quotes', 'sign'];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleUserClick = useCallback((event: any) => {
        if (
            event?.target?.id !== 'keep-underbar-card-open' &&
            !event?.relatedTarget?.hasAttribute('keep-underbar-card-open')
        ) {
            setTimeout(() => {
                setShowUnderbarCard(false);
            }, 100);
        }
    }, []);

    useEffect(() => {
        setSearchValue(query);
    }, [query]);

    useEffect(() => {
        window.addEventListener('click', handleUserClick);

        return () => {
            window.removeEventListener('click', handleUserClick);
        };
    }, [handleUserClick]);

    const handleSearch = () => {
        setShowUnderbarCard(false);
        refine(searchValue);
        // do not consider the initial search behavior as it is a worthless event from a tracking perspective
        if (searchValue !== '') {
            track({ name: TrackingEventName.SEARCH, params: { search_term: searchValue } });
            setItem(previousSearches.slice(0, MAX_SEARCHES), searchValue);
        }
        if (onSearch) {
            onSearch(searchValue);
        }
    };

    const debouncedSearch = useMemo(() => debounce(refine, 400), [refine]);

    const handleSearchValueChange = (value: string) => {
        setSearchValue(value);
        if (searchAsYouType) {
            debouncedSearch(value);
        }

        if (onChange) {
            onChange(value);
        }
    };

    const handleInputChange = ({ target: { value: newVal } }: ChangeEvent<HTMLInputElement>) => {
        handleSearchValueChange(newVal);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if ('Enter' === event.key) {
            handleSearch();
        }
    };

    const handleFocus = () => {
        if (withUnderbarCard) {
            setShowUnderbarCard(true);
        }
    };

    const resetSearch = () => {
        handleSearchValueChange('');
    };

    return (
        <Box sx={containerSx}>
            <Box sx={searchQueryContainerSx}>
                <Box sx={searchQueryWithIconSx}>
                    <Box sx={infoIconSx} />
                </Box>
                <InputBase
                    autoComplete="off"
                    autoFocus={autoFocus}
                    endAdornment={
                        searchValue && (
                            <CloseIcon
                                onClick={resetSearch}
                                sx={{ color: 'grey.400', cursor: 'pointer', mr: 2.5 }}
                            />
                        )
                    }
                    id="keep-underbar-card-open"
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                    onKeyPress={handleKeyPress}
                    placeholder={placeholder || t('algoliaSearchBox.placeholder')}
                    startAdornment={
                        withHints && (
                            <InfoIconWithHints
                                close={() => setShowHints(false)}
                                hints={hintsDisplayed}
                                isOpen={showHints}
                                open={() => setShowHints(true)}
                            />
                        )
                    }
                    sx={searchInputBaseSx(colorVersion)}
                    type="search"
                    value={searchValue}
                />
            </Box>
            {withShortcutHelper && (
                <Box sx={shortcutHelperIconSx}>
                    <SubdirectoryArrowLeftIcon sx={{ width: '18px' }} />
                </Box>
            )}
            {withSubmitButton && (
                <Box sx={{ float: 'right', ml: 'auto', mr: 1 }}>
                    <IconButton
                        data-test-id="submit-search"
                        onClick={handleSearch}
                        sx={searchButtonSx}
                    >
                        <SearchIcon />
                    </IconButton>
                </Box>
            )}
            {!showHints && showUnderbarCard && (
                <UnderbarCard
                    colorVersion={colorVersion}
                    searchQuery={searchValue}
                    withSuggestions
                />
            )}
        </Box>
    );
};

export default AlgoliaSearchBox;

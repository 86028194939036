import { Box, MenuItem as MuiMenuItem, Typography } from '@mui/material';
import { IndexUiState } from 'instantsearch.js';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { useInstantSearch } from 'react-instantsearch';
import { MENU_ITEM_ID } from '@components/NavBar/QuickSearch/consts';
import useRecentSearchMethods from '@components/search/algolia/RecentSearch/hooks/useRecentSearchMethods';
import { INDEX_NAME } from '@utils/consts/search';
import { sxs } from '@utils/mui/sx';
import { QuickSearchItem } from '@utils/types/search';

const centerSx = { alignItems: 'center', display: 'flex' };
const ellipsisSx = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' };
const enterIconSx = {
    '$root:hover &, $root:focus &, $root:focus-visible &': {
        visibility: 'visible',
    },
    mr: 1,
    visibility: 'hidden',
};
const textSx = {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 600,
    overflow: 'hidden',
    pl: 2,
};

const MenuItem: FC<QuickSearchItem> = ({
    Actions,
    dataTestId,
    Icon,
    href,
    savedSearchQuery,
    title,
    subtitle,
}) => {
    const { addItem } = useRecentSearchMethods();
    const { setIndexUiState } = useInstantSearch();
    const router = useRouter();

    const handleSelect = () => {
        let searchTerm = title;

        if (savedSearchQuery) {
            const { hierarchicalMenu, menu, query, refinementList } = savedSearchQuery;
            const newUiState: Partial<IndexUiState> = { query };
            if (hierarchicalMenu) newUiState.hierarchicalMenu = hierarchicalMenu;
            if (menu) newUiState.menu = menu;
            if (refinementList) newUiState.refinementList = refinementList;
            // TODO: migrate saved search to use new algolia format for ranges
            // if (range) newUiState.range = range;
            setIndexUiState(newUiState);
            searchTerm = query || title;
        } else if (href) {
            router.push(href);
        } else {
            setIndexUiState({ query: title });
        }

        addItem(searchTerm);

        if (router.pathname !== '/search') {
            router.push(`/search?${INDEX_NAME}[query]=${encodeURIComponent(searchTerm)}`);
        }
    };

    return (
        <MuiMenuItem
            className={MENU_ITEM_ID}
            data-test-id={dataTestId}
            onClick={handleSelect}
            sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}
        >
            <Box sx={centerSx}>
                {Icon}
                <Box sx={textSx}>
                    <Typography sx={sxs([ellipsisSx, { fontWeight: 'medium' }])}>
                        {title}
                    </Typography>
                    {subtitle && <Typography sx={ellipsisSx}>{subtitle}</Typography>}
                </Box>
            </Box>
            <Box sx={centerSx}>
                <Typography
                    alt="enterKey"
                    aria-label="enter"
                    component="img"
                    src="/assets/enterKey.svg"
                    sx={enterIconSx}
                />
                {Actions}
            </Box>
        </MuiMenuItem>
    );
};

export default MenuItem;

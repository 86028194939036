import SearchIcon from '@mui/icons-material/Search';
import { MenuList, PaletteMode, Theme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import MenuItem from './MenuItem';
import SavedAndRecentSearches from './SavedAndRecentSearches';
import { fetchSuggestions } from '@utils/search/fetchSuggestions';
import { QuickSearchItem } from '@utils/types/search';

interface SuggestionsProps {
    items?: QuickSearchItem[];
    searchQuery: string;
    colorVersion?: PaletteMode;
    withSuggestions?: boolean;
}

const suggestionSx = (colorVersion: PaletteMode) => ({
    '& li': {
        '&:hover, &:focus': {
            bgcolor: colorVersion === 'dark' ? 'common.black' : 'grey.700',
            outline: (theme: Theme) =>
                `1px solid ${theme.palette.grey[colorVersion === 'dark' ? 200 : 500]}`,
        },
        m: 0,
        mb: 1 / 8,
    },
    color: colorVersion === 'dark' ? 'common.white' : 'grey.100',
});

const Suggestions: FC<SuggestionsProps> = ({
    items,
    searchQuery,
    colorVersion,
    withSuggestions = false,
}) => {
    const [suggestions, setSuggestions] = useState<string[]>([]);

    const getSuggestions = async (searchQueryTerm: string) => {
        const fetchedSuggestions = await fetchSuggestions(searchQueryTerm);
        setSuggestions(fetchedSuggestions);
    };

    useEffect(() => {
        if (withSuggestions) {
            getSuggestions(searchQuery);
        }
    }, [searchQuery, withSuggestions]);

    return (
        <MenuList
            data-test-id="quicksearch-list-results"
            sx={suggestionSx(colorVersion || 'light')}
        >
            {/* Suggestions Algolia */}
            {suggestions?.map((suggestion, index) => (
                <MenuItem
                    key={`suggestion-${suggestion}-${+index}`}
                    Icon={<SearchIcon />}
                    colorVersion={colorVersion}
                    dataTestId="algolia-suggestion-item"
                    title={suggestion}
                />
            ))}
            <SavedAndRecentSearches colorVersion={colorVersion} searchQuery={searchQuery} />
            {items?.map((item, index) => (
                <MenuItem
                    {...item}
                    key={`menu-item-${item.title}-${+index}`}
                    colorVersion={colorVersion}
                    dataTestId="quicksearch-result-item"
                />
            ))}
            {searchQuery && (
                <MenuItem Icon={<SearchIcon />} colorVersion={colorVersion} title={searchQuery} />
            )}
        </MenuList>
    );
};
export default Suggestions;

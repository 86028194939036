import algoliasearch, { SearchClient } from '@livv/custom-algoliasearch/lite';
import { BaseHit, Hit } from 'instantsearch.js';
import { getEnvVar } from '@config/configEnv';
import getSearchApiKey from '@utils/search/getSearchApiKey';

// Extend the Hit type with a `query` property to store the suggestion
export type ExtendedHit<THit extends BaseHit> = Hit<THit> & {
    query: string;
};

// Function guard to validate that the suggestion is a string
const isString = (value: unknown): value is string => typeof value === 'string';

/**
 * Fetches suggestions from the Algolia index
 * @param {string} searchQuery - The search query
 * @returns {Promise<string[]>} - The suggestions
 */
export const fetchSuggestions = async (searchQuery: string): Promise<string[]> => {
    try {
        const ALGOLIA_INDEX_SUGGESTIONS = getEnvVar('ALGOLIA_INDEX_SUGGESTIONS');
        if (!ALGOLIA_INDEX_SUGGESTIONS) {
            throw new Error('ALGOLIA_INDEX_SUGGESTIONS is not defined in environment variables');
        }

        // We force the isPremiumUser to be true to avoid the error too many requests
        // and we don't need restrictions for this index (suggestions)
        const { appId, searchKey } = await getSearchApiKey(true);
        const searchClient: SearchClient = algoliasearch(appId, searchKey);
        const index = searchClient.initIndex(ALGOLIA_INDEX_SUGGESTIONS);

        const { hits } = await index.search<ExtendedHit<Record<string, unknown>>>(searchQuery, {
            hitsPerPage: 5,
        });

        return hits.map((hit) => hit.query).filter(isString);
    } catch (error) {
        console.error('Error fetching suggestions:', error);

        return [];
    }
};
